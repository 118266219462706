import { DatePipe } from '@angular/common';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { faCheck, faEraser, faExclamationTriangle, faPaperclip, faPlus, faSave, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { TokenPlanta } from 'src/app/model/autenticacion';
import { Aviso } from 'src/app/model/aviso';
import { Ayuda } from 'src/app/model/ayuda';
import { Beneficiario } from 'src/app/model/beneficiario';
import { Coeficiente } from 'src/app/model/coeficiente';
import { Curso } from 'src/app/model/curso';
import { Empleado } from 'src/app/model/empleado';
import { LabelValue } from 'src/app/model/labelvalue';
import { Presupuesto } from 'src/app/model/presupuesto';
import { TipoAyudaContrato } from 'src/app/model/tipoayuda';
import { AyudasService } from 'src/app/services/ayudas.service';
import { BeneficiarioService } from 'src/app/services/beneficiario.service';
import { PresupuestosService } from 'src/app/services/presupuestos.service';
import { ConfirmaciondialogComponent } from '../../dialog/confirmaciondialog/confirmaciondialog.component';

@Component({
  selector: 'app-ayuda',
  templateUrl: './ayuda.component.html',
  styleUrls: ['./ayuda.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class AyudaComponent implements OnInit {
  faTimes = faTimes;
  faSearch = faSearch;
  faEraser = faEraser;
  faPlus = faPlus;
  faSave = faSave;
  faPaperClip = faPaperclip;
  faCheck = faCheck;

  date = new UntypedFormControl(moment());

  rolesUsuario: string[];
  esUsuario: boolean = false;
  expediente: number;
  empleado: Empleado = new Empleado();
  ayuda: Ayuda = new Ayuda();
  ayudas: Ayuda[] = [];
  tipoAyudas: TipoAyudaContrato[] = [];
  beneficiarios: Beneficiario[] = [];
  valido: boolean = true;
  fileToUpload: File | null = null;
  presupuestos: Presupuesto[] = [];
  presupuestoSel: Presupuesto;
  tipoAyudaSel: TipoAyudaContrato;
  cursos: Curso[] = [];
  cursoSel: Curso;
  coeficientes: Coeficiente[] = [];
  tipoAyudaCampamento: number[] = [2];
  esCampamento: boolean = false;

  @Input() idUsuario: number;
  esRevision: boolean = false;

  @ViewChild('file') inputFile: ElementRef;

  // Tabla
  dataSource: MatTableDataSource<Ayuda>;
  displayColumns: string[] = ['fecha', 'ayuda', 'beneficiario', 'importeFactura', 'importe', 'observaciones', 'nomina', 'adjunto', 'borrar'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  selectedRow: Ayuda;

  constructor(private ayudasService: AyudasService, private beneficiarioService: BeneficiarioService, private presupuestosService: PresupuestosService
    , private datePipe: DatePipe, public dialog: MatDialog, public translate: TranslateService) { }

  ngOnInit(): void {
    let ps = localStorage.getItem("plantaSelAtenciones") ? localStorage.getItem("plantaSelAtenciones") : sessionStorage.getItem("plantaSelAtenciones");
    let plantaUsuario: TokenPlanta = JSON.parse(ps);
    this.rolesUsuario = plantaUsuario.rol.split(",");
    // Si es el rol "usuario" tiene que rellenar con su expediente y nombre
    if (this.idUsuario) {
      this.esRevision = true;
    } else if (this.rolesUsuario.includes("usuario")) {
      let id = localStorage.getItem("idUsuarioAtenciones") ? localStorage.getItem("idUsuarioAtenciones") : sessionStorage.getItem("idUsuarioAtenciones");
      this.idUsuario = Number.parseInt(id);
      this.esUsuario = true;
      // Quitar la columan importe de la tabla
      this.displayColumns = this.displayColumns.filter(dc => dc != "importe");
    }

    this.dataSource = new MatTableDataSource([]);
    this.buscarCoeficientes();
  }

  buscarEmpleado(empleado: Empleado): void {
    this.empleado = empleado;
    this.buscarBeneficiario();
    this.buscarTipoAyudas();
    this.buscarAyudas();
    this.buscarCursos();
    this.limpiarAyuda();
  }

  buscarTipoAyudas() {
    this.ayudasService.getTipoAyudas(this.empleado.idContractType).subscribe({
      next: result => {
        this.tipoAyudas = result;
      },
      error: error => console.error(error)
    });
  }

  buscarBeneficiario(): void {
    this.beneficiarioService.getBeneficiarios(this.empleado.idUsuario).subscribe({
      next: result => {
        this.beneficiarios = result;
      },
      error: error => console.error(error)
    });
  }

  buscarCursos() {
    this.ayudasService.getAyudaEstudios(this.empleado.idContractType).subscribe({
      next: result => {
        this.cursos = result;
      },
      error: error => console.error(error)
    });
  }

  buscarCoeficientes() {
    this.ayudasService.getCoeficientes().subscribe({
      next: result => {
        this.coeficientes = result;
      },
      error: error => console.error(error)
    });
  }

  buscarPresupuestos(): void {
    if (this.ayuda.idTipoAyuda) {
      this.presupuestosService.getPresupuestosUsuarioAyuda(this.empleado.idUsuario, this.ayuda.idTipoAyuda, this.ayuda.idBeneficiario).subscribe(result => {
        this.presupuestos = result;
        if (this.ayuda && this.ayuda.idPresupuesto) {
          this.presupuestoSel = this.presupuestos.filter(p => p.idPresupuesto == this.ayuda.idPresupuesto)[0];
        }
      });
    }
  }

  buscarAyudas(): void {
    this.ayudasService.getAyudas(this.empleado.idUsuario).subscribe(result => {
      this.ayudas = result;
      if (this.esUsuario) {
        this.ayudas = this.ayudas.filter(option => option.importeFactura != null);
      }
      this.dataSource = new MatTableDataSource(this.ayudas);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }, error => console.error(error));
  }

  filtrarAyudas(): void {
    this.tipoAyudaSel = this.tipoAyudas.filter(f => f.idTipoAyudaContrato == this.ayuda.idTipoAyuda)[0];
    this.buscarPresupuestos();
    this.esCampamento = this.tipoAyudaCampamento.includes(this.tipoAyudaSel.idTipoAyuda);

    if (!this.esUsuario) {
      if (this.ayuda.idTipoAyuda && this.ayuda.idBeneficiario) {
        this.dataSource.data = this.ayudas.filter(option => option.idTipoAyuda == this.ayuda.idTipoAyuda && option.idBeneficiario == this.ayuda.idBeneficiario);
      } else if (this.ayuda.idTipoAyuda) {
        this.dataSource.data = this.ayudas.filter(option => option.idTipoAyuda == this.ayuda.idTipoAyuda);
      } else if (this.ayuda.idBeneficiario) {
        this.dataSource.data = this.ayudas.filter(option => option.idBeneficiario == this.ayuda.idBeneficiario);
      } else {
        this.dataSource.data = this.ayudas;
      }

      if (this.ayuda.importeFactura) {
        if (this.tipoAyudaSel.porcentajeAyuda != null) {
          this.ayuda.importe = this.ayuda.importeFactura * (this.tipoAyudaSel.porcentajeAyuda / 100);
        } else {
          this.ayuda.importe = this.ayuda.importeFactura;
        }
      }
    }
  }

  validar(): boolean {
    this.valido = true;
    if (!this.ayuda.idTipoAyuda) {
      this.valido = false;
    }
    if (!this.ayuda.importe || this.ayuda.importe == 0) {
      this.valido = false;
    }
    if (this.esUsuario && !this.fileToUpload && !this.ayuda.idAyudaAdjunto) {
      // Comprobamos las excepciones de ayudas de estudios para Inespal que no necesitan adjunto
      if (this.empleado.idPlanta == 12) {
        if (!this.cursoSel.docOpcional) {
          this.valido = false;
        }
      }
      else {
        this.valido = false;
      }
    }
    if (this.tipoAyudaSel.idTipoAyuda == 4 && !this.ayuda.idCurso) {
      this.valido = false;
    }
    if (this.tipoAyudaSel.idTipoAyuda == 4 && this.empleado.idPlanta == 12 && (!this.ayuda.curso || this.ayuda.curso.length == 0)) {
      this.valido = false;
    }
    if (this.esUsuario && this.esCampamento && !this.ayuda.idPresupuesto) {
      this.valido = false;
    }
    // Verificar que si tiene presupuesto el importe esta dentro del permitido
    if (this.presupuestoSel) {
      let importeMax: number = this.presupuestoSel.importe - this.presupuestoSel.facturado;
      if (this.ayuda.importeFactura > importeMax) {
        this.valido = false;
      }
    }

    return this.valido;
  }

  altaAyuda(): void {
    if (this.validar()) {
      this.ayuda.idUsuario = this.empleado.idUsuario;
      this.ayuda.fecha = this.date.value;
      this.ayuda.fechaStr = this.datePipe.transform(this.date.value, 'yyyy-MM-dd');
      if (this.rolesUsuario.includes("people")) {
        this.ayuda.estado = 1;
      }
      if (this.ayuda.idAyuda) {
        if (this.fileToUpload) {
          this.ayudasService.modificaAyudaFichero(this.ayuda, this.fileToUpload).subscribe(result => {
            if (this.rolesUsuario.includes("people")) {
              this.cerrarPrespuesto(false);
            }
            this.limpiarAyuda();
            this.buscarAyudas();
          });
        } else {
          this.ayudasService.modificaAyuda(this.ayuda).subscribe(result => {
            if (this.rolesUsuario.includes("people")) {
              this.cerrarPrespuesto(false);
            }
            this.limpiarAyuda();
            this.buscarAyudas();
          });
        }
      } else {
        this.ayudasService.altaAyuda(this.ayuda, this.fileToUpload).subscribe(result => {
          if (this.rolesUsuario.includes("people")) {
            this.cerrarPrespuesto(false);
          }
          this.limpiarAyuda();
          this.buscarAyudas();
        });
      }
    }
  }

  private cerrarPrespuesto(esAprobacion: boolean): void {
    let cantidad: number = esAprobacion ? 0 : this.ayuda.importeFactura;

    if (this.presupuestoSel != null && (this.presupuestoSel.importe - this.presupuestoSel.facturado == cantidad)) {
      // Marcar el presupuesto como cerrado
      this.presupuestoSel.idEstado = 3;
      this.presupuestosService.modificacionPresupuesto(this.presupuestoSel).subscribe(result => {
        console.log("Modificado presupuesto");
      });
    }
  }

  private reabrirPrespuesto(idPresupuesto: number, idUsuario: number): void {
    this.presupuestosService.getPresupuestosUsuario(idUsuario).subscribe(result => {
      let pres: Presupuesto = result.filter(p => p.idPresupuesto == idPresupuesto)[0];
      pres.idEstado = 1;
      this.presupuestosService.modificacionPresupuesto(pres).subscribe();
    });
  }

  seleccionarAyuda(ayuda: Ayuda): void {
    if (this.ayuda.idAyuda == ayuda.idAyuda) {
      this.limpiarAyuda();
      delete this.tipoAyudaSel;
    } else {
      const myJSON = JSON.stringify(ayuda);

      this.ayuda = JSON.parse(myJSON);
      this.tipoAyudaSel = this.tipoAyudas.filter(f => f.idTipoAyudaContrato == this.ayuda.idTipoAyuda)[0];
      if (this.ayuda.idTipoAyuda) {
        this.buscarPresupuestos();
      }
      this.selectedRow = ayuda;
    }
  }

  confirmarBorrarAyuda(ayuda: Ayuda): void {
    let aviso: Aviso = new Aviso();
    aviso.icono = faExclamationTriangle;
    aviso.claseIcono = "text-warning";
    aviso.titulo = "Atención";
    aviso.mensaje = `Va a eliminar la ayuda ${ayuda.descTipoAyuda} del día ${this.datePipe.transform(ayuda.fecha, 'dd/MM/yyyy')}. ¿Desea continuar?`;
    const dialogRef = this.dialog.open(ConfirmaciondialogComponent, {
      width: "400px",
      disableClose: true,
      data: aviso
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.borraAyuda(ayuda);
      }
    });
  }

  borraAyuda(ayuda: Ayuda): void {
    this.ayudasService.borraAyuda(ayuda.idAyuda).subscribe(result => {
      if (ayuda.idPresupuesto != null) {
        this.reabrirPrespuesto(ayuda.idPresupuesto, ayuda.idUsuario);
      }
      if (result) {
        this.limpiarAyuda();
        this.buscarAyudas();
      }
    }, error => console.error(error));
  }

  limpiarAyuda(): void {
    this.selectedRow = null;
    this.valido = true;
    this.ayuda = new Ayuda();
    this.fileToUpload = null;
    this.inputFile.nativeElement.value = '';
    this.dataSource.data = this.ayudas;
    delete this.presupuestoSel;
    this.presupuestos = [];
  }

  subirFichero(files: FileList): void {
    this.fileToUpload = files.item(0);
  }

  borrarFichero() {
    this.ayudasService.deleteAyudaAdjunto(this.ayuda.idAyudaAdjunto).subscribe(result => {
    });
  }

  verFichero(ayuda: Ayuda): void {
    this.ayudasService.getAyudasAdjunto(ayuda.idAyudaAdjunto).subscribe(result => {
      var downloadURL = window.URL.createObjectURL(result);
      window.open(downloadURL);
    });
  }

  revisar(): void {
    this.ayuda.estado = 1;
    this.ayudasService.modificaAyuda(this.ayuda).subscribe(result => {
      this.cerrarPrespuesto(true);
      this.limpiarAyuda();
      this.buscarAyudas();
    });
  }

  denegar(): void {
    console.log(this.ayuda);
    if (this.ayuda.observaciones) {
      this.ayuda.estado = 2;
      this.ayudasService.modificaAyuda(this.ayuda).subscribe(result => {
        this.ayudasService.enviarMailAyuda(this.ayuda).subscribe();
        this.limpiarAyuda();
        this.buscarAyudas();
      });
    } else {
      this.valido = false;
    }
  }

  onFocusOutImporte() {
    // Controlar valor negativo
    if (this.ayuda.importeFactura < 0) {
      this.ayuda.importeFactura = Math.abs(this.ayuda.importeFactura);
    }
    if (this.ayuda.idTipoAyuda) {
      this.ayuda.importe = this.ayuda.importeFactura;
      if (this.tipoAyudaSel != null && this.tipoAyudaSel.porcentajeAyuda != null) {
        this.ayuda.importe = this.ayuda.importeFactura * (this.tipoAyudaSel.porcentajeAyuda / 100);
      }
    }
  }

  presupuestoChange(pre: Presupuesto) {
    if (pre) {
      this.ayuda.idPresupuesto = pre.idPresupuesto;
    } else {
      this.ayuda.idPresupuesto = null;
    }
  }

  verFicheroPresupuesto(): void {
    this.presupuestosService.getPresupuestoAdjunto(this.presupuestoSel.idPresupuestoAdjunto).subscribe(result => {
      var downloadURL = window.URL.createObjectURL(result);
      window.open(downloadURL);
    });
  }

  seleccionarEstudios(): void {
    let numHijos = this.beneficiarios.filter(b => b.parentesco.value == 2).length;
    this.cursoSel = this.cursos.filter(c => c.idCurso == this.ayuda.idCurso)[0];
    this.ayuda.importeFactura = this.getImporte(numHijos, this.cursoSel);
    this.onFocusOutImporte();
  }

  private getImporte(numHijos: number, curso: Curso): number {
    let importeCalculado = curso.importe;

    // Si el curso es Master (id = 10) no se calcula coeficiente
    if (curso.idCurso != 10) {
      let coeficiente: number = this.coeficientes.some(c => c.hijos == numHijos) ? this.coeficientes.filter(c => c.hijos == numHijos)[0].coeficiente : 1;
      importeCalculado = curso.importe * coeficiente;
    }

    return Number(importeCalculado.toFixed(2));
  }
}
